/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var CopyCompany = {
        // All pages
        'common': {
            init: function () {
                if( ! localStorage.getItem('popup') ) {
                    $('.top-popup').slideDown();
                }

                $(document).on('click', '.top-popup-close', function() {
                    localStorage.setItem('popup', "closed" );
                    $('.top-popup').slideUp();
                });

                $('.home-banner .slider').slick({
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 4000,
                    fade: true,
                    cssEase: 'linear',
                    infinite: true
                });

                // JavaScript to be fired on all pages
                // Hide Header on on scroll down
                var didScroll;
                var lastScrollTop = 0;
                var delta = 5;
                var navbarHeight = $('header').outerHeight();

                $(window).scroll(function (event) {
                    didScroll = true;

                    if ($(this).scrollTop() > 150) {
                        $('header').addClass('background');
                    } else {
                        $('header').removeClass('background');
                    }
                });

                setInterval(function () {
                    if (didScroll) {
                        hasScrolled();
                        didScroll = false;
                    }
                }, 250);

                function hasScrolled() {
                    var st = $(this).scrollTop();

                    // Make sure they scroll more than delta
                    if (Math.abs(lastScrollTop - st) <= delta)
                        return;

                    // If they scrolled down and are past the navbar, add class .nav-up.
                    // This is necessary so you never see what is "behind" the navbar.
                    if (st > lastScrollTop && st > navbarHeight) {
                        // Scroll Down
                        $('header').removeClass('nav-down').addClass('nav-up');
                    } else {
                        // Scroll Up
                        if (st + $(window).height() < $(document).height()) {
                            $('header').removeClass('nav-up').addClass('nav-down');
                        }
                    }

                    lastScrollTop = st;
                }
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },
        // Home page
        'home': {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        'about_us': {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = CopyCompany;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
