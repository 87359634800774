(function ($) {
    var Popup = {
        init: function () {
            this.handleClosePopup();
            this.maybeshowPopup();
        },

        handleClosePopup: function () {
            $(document).on('click', '.top-popup-close', function (e) {
                e.preventDefault();

                Cookies.set('cc_close_popup', true, {expires: 365});

                $('.popup').removeClass('active');

                setTimeout(function () {
                    $('.popup').remove();
                }, 400);
            });
        },

        maybeshowPopup: function () {
            if (typeof Cookies.get('cc_close_popup') === 'undefined') {
                $('.popup').addClass('active');
            } else {
                $('.popup').remove();
            }
        }
    };

    if ($('.popup').length > 0) {
        Popup.init();
    }
})(jQuery);