(function($) {
    jQuery(document).ready(function($) {

        /* sticky footer function */
        StickyFooter();

        $('.menu-trigger').click(function (e) {
            e.preventDefault();

            $(this).stop().toggleClass('open');
            $('body, html').stop().toggleClass('scroll-hidden');
            $('.navbar').stop().toggleClass('open');
        });

        if ($('#mobile-menu ul ul').length > 0) {
            $('#mobile-menu ul ul ').before('<em class="submenu-caret"></em>');
        }

        $('.submenu-caret').click(function () {
            $(this).next().slideToggle();
            $(this).toggleClass('toggled');
            $(this).parent().siblings().find('ul').slideUp();
            $(this).parent().siblings().find('em').removeClass('toggled');
        });


        /* Src to Background */
        $('.banner .banner-img, .welcome-blk .welcome-blk-r, .overview-sec .overview-blk .overview-blk-img, .onzeprojecten-blk .onzeprojecten-blk-img').each(function () {
            $(this).css('background-image', 'url(' + $(this).find('img').attr('src') + ')');
            $(this).find('img').css('opacity', '0');
        });


        /*  */
        $('.clients-slide').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000
        });
    });


    /* Script on resize
    ---------------------------------*/
    jQuery(window).resize(function ($) {

        /* sticky footer function */
        setTimeout(function () {
            StickyFooter();
        }, 500);

    });

    /* Script on load
    ----------------------------------*/
    jQuery(window).load(function ($) {
        jQuery('.loader-container').addClass('done');

        /* sticky footer function */
        setTimeout(function () {
            StickyFooter();
        }, 500);
    });

    /* Script all functions
    ----------------------------------*/

    /* sticky footer function */
    function StickyFooter() {
        var Stickyfooter = jQuery('footer').outerHeight();
        jQuery('#wrapper').css('margin-bottom', -Stickyfooter);
        jQuery('#wrapper').css('padding-bottom', Stickyfooter);
    }

    /* equalheight */

    equalheight = function(container){

        var currentTallest = 0,
            currentRowStart = 0,
            rowDivs = [],
            $el,
            topPosition = 0;
        $(container).each(function() {

            $el = $(this);
            $($el).height('auto');
            topPostion = $el.position().top;

            if (currentRowStart != topPostion) {
                for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                    rowDivs[currentDiv].height(currentTallest);
                }
                rowDivs.length = 0; // empty the array
                currentRowStart = topPostion;
                currentTallest = $el.height();
                rowDivs.push($el);
            } else {
                rowDivs.push($el);
                currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
            }
            for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
                rowDivs[currentDiv].height(currentTallest);
            }
        });
    };

    jQuery(window).load(function($) {
        equalheight('.request_sec .equal-hei');
    });


    jQuery(window).resize(function($){
        equalheight('.request_sec .equal-hei');
    });
})(jQuery);